import '../styles/wns.css';

import React, {  useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Carousel from 'react-bootstrap/Carousel';

import Layout from '../components/layout';
import Seo from '../components/seo';
import custom1 from '../images/custom1.svg';
import custom2 from '../images/custom2.svg';
import custom3 from '../images/custom3.svg';
import expand_circle_down from '../images/expand_circle_down.svg';
import expand_circle_down_arrow from '../images/expand_circle_down_arrow.svg';
import pr1 from '../images/pr1.svg';
import pr2 from '../images/pr2.svg';
import pr3 from '../images/pr3.svg';
import pr4 from '../images/pr4.svg';
import pr5 from '../images/pr5.svg';
import pr6 from '../images/pr6.svg';
import sensible1 from '../images/sensible1.svg';
import sensible2 from '../images/sensible2.svg';
import sensible3 from '../images/sensible3.svg';
import simple1 from '../images/simple1.svg';
import simple2 from '../images/simple2.svg';
import simple3 from '../images/simple3.svg';
import simple4 from '../images/simple4.svg';
import slide1 from '../images/slide1.svg';
import slide2 from '../images/slide2.svg';
import wnslogo from '../images/wnslogo.svg';


function WNS() {

  const [index, setIndex] = useState( 0 );

  const handleSelect = ( selectedIndex, e ) => {
    setIndex( selectedIndex );
  };

  const [showArrow, setArrow] = useState( -1 );

  function clicked( e, i ){
    console.log( i );
    if( showArrow === i ){
      setArrow( -1 );
    } else {
      setArrow( i );
    }
  }

  useEffect( () => {

  }, [showArrow] );

  return(
    <Layout header={'menu'}>
      <Seo
        canonicalUrl="https://www.getmybubble.com/wns/"
        schema={[
          JSON.stringify(   {
            '@context': 'http://schema.org',
            '@type': 'Product',
            'name': 'WnS',
            'aggregateRating': { '@type': 'AggregateRating', 'ratingValue': '4.4 ', 'reviewCount': '6' } } ),
          JSON.stringify(  {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'url': 'https://www.getmybubble.com/',
            'logo': 'https://www.getmybubble.com/static/buble-077b4498b854b2301e9cbf5a55b69b3d.svg'
          } )]}/>

      <div className="wnsbanner-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Breadcrumb>
                <Breadcrumb.Item href="#">Insurance</Breadcrumb.Item>

                <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                  Life Insurance
                </Breadcrumb.Item>

                <Breadcrumb.Item active>W&S</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="col-12">
              <div className="d-flex media descp">
                <img className="" src={wnslogo} alt="" />

                <div className="media-body">
                  <ul>
                    {/* <li>
                      <p><span>AA-</span>S&P Global</p>
                    </li> */}

                    <li>
                      <p><span>A+</span>A.M. Best</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 text-center">
              <h2>IncomeSense through <br/> Western & Southern Financial Group</h2>
            </div>

            <div className="col-12 text-center">
              <ul>
                <li>
                  <div className="box-list">
                    <p>Monthly <br/> payout for your family.</p>
                  </div>
                </li>

                <li>
                  <div className="box-list">
                    <p>Coverage duration up to 30 years (not beyond age 65).</p>
                  </div>
                </li>

                <li>
                  <div className="box-list">
                    <p>Monthly coverage up to $10,000 based on your age & policy term.</p>
                  </div>
                </li>

                <li>
                  <div className="box-list">
                    <p>Issued through W&S a carrier with an A+ rating from A.M. Best</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-12 text-center mgb-16">
              <a href="" className="common-btn orange-btn">Get quote <img className="m-0" src={expand_circle_down} alt=""/></a>
            </div>

            <div className="col-12 text-center">
              {/* <a href="" className="common-btn knowbtn collapsed" data-bs-toggle="collapse" data-bs-target="#knowmore" aria-expanded="false">Know more <img className="m-0" src={expand_circle_down_arrow} alt=""/></a>

              <div id="knowmore" className="collapse">
                <p>IncomeSense is a decreasing insurance policy, designed to help your family by providing them with a monthly payout, rather than a lump sum amount at the end of the insurance term. It can be customized to your family’s financial needs
                                  and can be more affordable than traditional life insurance policies because you buy only as much coverage as you need. Help protect your family’s future...</p>
              </div> */}

              <Accordion>
                <div className="col-12">
                  <div className="accordion row" id="accordionExample1">
                    <div className="col-12 ">
                      <Card>
                        <Accordion.Toggle onClick={( e ) => clicked( e, 11 )} as={Card.Header} eventKey={11}>
                          <div className="card-head" id="headingEleven">
                            <h2 className={showArrow !== 11 ? 'collapsed mb-0  common-btn knowbtn' : 'mb-0  common-btn knowbtn'}>
                              Know more <img className="m-0" src={expand_circle_down_arrow} alt=""/>
                            </h2>
                          </div>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey={11}>
                          <Card.Body>
                            <p>IncomeSense is a decreasing insurance policy, designed to help your family by providing them with a monthly payout, rather than a lump sum amount at the end of the insurance term. It can be customized to your family’s financial needs
                                  and can be more affordable than traditional life insurance policies because you buy only as much coverage as you need. Help protect your family's future by setting them up with a monthly income source.</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </div>
                </div>
              </Accordion>

            </div>
          </div>
        </div>
      </div>

      <div className="why-section-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2><span>Why</span> choose IncomeSense?</h2>
            </div>
          </div>
        </div>

        <div className="why-section"></div>

        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <ul>
                <li>
                  <div className="box-list">
                    <p>Monthly <br/> payout for your family.</p>
                  </div>
                </li>

                <li>
                  <div className="box-list">
                    <p>Coverage duration up to 30 years (not beyond age 65).</p>
                  </div>
                </li>

                <li>
                  <div className="box-list">
                    <p>Monthly coverage up to $10,000 based on your age & policy term.</p>
                  </div>
                </li>

                <li>
                  <div className="box-list">
                    <p>Issued through W&S a carrier with an A+ rating from A.M. Best</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-12 text-center">

              <Accordion>
                <div className="col-12">
                  <div className="accordion row" id="accordionExample2">
                    <div className="col-12 ">
                      <Card>
                        <Accordion.Toggle onClick={( e ) => clicked( e, 12 )} as={Card.Header} eventKey={12}>
                          <div className="card-head" id="headingTwelve">
                            <h2  className={showArrow !== 12 ? 'collapsed mb-0  common-btn knowbtn' : 'mb-0  common-btn knowbtn'}>
                              Know more <img className="m-0" src={expand_circle_down_arrow} alt=""/>
                            </h2>
                          </div>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey={12}>
                          <Card.Body>
                            <p>IncomeSense is a decreasing insurance policy, designed to help your family by providing them with a monthly payout, rather than a lump sum amount at the end of the insurance term. It can be customized to your family’s financial needs
                                  and can be more affordable than traditional life insurance policies because you buy only as much coverage as you need. Help protect your family's future by setting them up with a monthly income source.</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </div>
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <div className="how-section-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">

              <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                  <img className="d-block" src={slide1} alt="First slide" />
                </Carousel.Item>

                <Carousel.Item>
                  <img className="d-block" src={slide2} alt="Second slide" />
                </Carousel.Item>
              </Carousel>

            </div>

            <div className="col-12 col-md-6">
              <div className="detailinfo">
                <div className="row">
                  <div className="col-md-4 col-4">
                    <div className="detailbox">
                      <h4>Age</h4>

                      <p><span>35</span>year-old</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-4">
                    <div className="detailbox">
                      <h4>Term</h4>

                      <p><span>30</span>years</p>
                    </div>
                  </div>

                  <div className="col-md-4 col-4">
                    <div className="detailbox">
                      <h4>Payout</h4>

                      <p><span>$2500</span>/month</p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <p>If you are 35 years of age and buy a 30-year policy (coverage till you’re 65), whereupon death, the payout comes out to $2,500 per month.</p>

                    <p>If you live five years longer, your family would receive the monthly payout of $2,500 per month for 25 years ($750,000 in total). Your beneficiary is guaranteed a minimum five-year payout.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="products-section-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Product <span>highlights</span></h2>
            </div>

            <div className="common-space">
              <div className="col-12">
                <div className="row">
                  <div className="media col-12 col-md-4">
                    <img className="mr-3" src={pr1} alt="" />

                    <div className="media-body">
                      <p>Coverage duration up to 30 years (not beyond age 65)</p>
                    </div>
                  </div>

                  <div className="media col-12 col-md-4">
                    <img className="mr-3" src={pr2} alt="" />

                    <div className="media-body">
                      <p>Policy Available for people between the ages of 20 & 55</p>
                    </div>
                  </div>

                  <div className="media col-12 col-md-4">
                    <img className="mr-3" src={pr3} alt="" />

                    <div className="media-body">
                      <p>Monthly insurance premiums guaranteed to never increase</p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="media col-12 col-md-4">
                    <img className="mr-3" src={pr4} alt="" />

                    <div className="media-body">
                      <p>Guaranteed monthly payouts from $500 up to $10,000 so long as premiums are paid</p>
                    </div>
                  </div>

                  <div className="media col-12 col-md-4">
                    <img className="mr-3" src={pr5} alt="" />

                    <div className="media-body">
                      <p>Expert agents on standby to help you when you need them</p>
                    </div>
                  </div>

                  <div className="media col-12 col-md-4">
                    <img className="mr-3" src={pr6} alt="" />

                    <div className="media-body">
                      <p>Easy Online application will provide coverage upon approval and payment of premium</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="benefits-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>The <span>benefits</span> of

                <br/> IncomeSense through W&S</h2>
            </div>
          </div>

          <div className="simplebox">
            <div className="row">
              <div className="col-12 text-center">
                <h3>Simple:</h3>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="media col-6 col-md-3">
                    <img className="mr-3" src={simple1} alt="" />

                    <div className="media-body">
                      <p>Answer just 8 questions to get your quote online</p>
                    </div>
                  </div>

                  <div className="media col-6 col-md-3">
                    <img className="mr-3" src={simple2} alt="" />

                    <div className="media-body">
                      <p>No need for medical exams if you're a healthy individual</p>
                    </div>
                  </div>

                  <div className="media col-6 col-md-3">
                    <img className="mr-3" src={simple3} alt="" />

                    <div className="media-body">
                      <p>Experts available to help you whenever you need them</p>
                    </div>
                  </div>

                  <div className="media col-6 col-md-3">
                    <img className="mr-3" src={simple4} alt="" />

                    <div className="media-body">
                      <p>Easy online application will provide coverage upon approval and payment of premium</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="two-col-box">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="sensiblebox">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h3>Sensible:</h3>
                    </div>

                    <div className="col-12">
                      <div className="row">
                        <div className="d-flex media col-12 mgb-16">
                          <img className="mr-3" src={sensible1} alt="" />

                          <div className="media-body">
                            <p>Guaranteed monthly payouts for your family with rates never to increase so long as premiums are paid</p>
                          </div>
                        </div>

                        <div className="d-flex media col-12 mgb-16">
                          <img className="mr-3" src={sensible2} alt="" />

                          <div className="media-body">
                            <p>Helps provide your family with a monthly income source after your passing</p>
                          </div>
                        </div>

                        <div className="d-flex media col-12">
                          <img className="mr-3" src={sensible3} alt="" />

                          <div className="media-body">
                            <p>Issued through W&S a carrier with an A+ rating from A.M. Best </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="sensiblebox">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h3>Customizable:</h3>
                    </div>

                    <div className="col-12">
                      <div className="row">
                        <div className="d-flex media col-12 mgb-16">
                          <img className="mr-3" src={custom1} alt="" />

                          <div className="media-body">
                            <p>Policy is available for people between the ages of 20 & 55</p>
                          </div>
                        </div>

                        <div className="d-flex media col-12 mgb-16">
                          <img className="mr-3" src={custom2} alt="" />

                          <div className="media-body">
                            <p>Monthly coverage up to $10,000 based on your age & policy term</p>
                          </div>
                        </div>

                        <div className="d-flex media col-12">
                          <img className="mr-3" src={custom3} alt="" />

                          <div className="media-body">
                            <p>Coverage for up to 30 years (not beyond the age of 65)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faqsection">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2><span>FAQ</span></h2>
            </div>

            <Accordion>
              <div className="col-12">
                <div className="accordion row" id="accordionExample">
                  <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 1 )} as={Card.Header} eventKey={1}>
                        <div className={'card-head '} id="headingOne">
                          <h2 className={showArrow !== 1 ? 'collapsed mb-0' : 'mb-0'}>
                            {/* 1. What is a decreasing term life policy? */}
                            1. What is term life insurance?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={1}>
                        <Card.Body>
                          {/* <p>With decreasing term life insurance, you choose the coverage you want and that sum decreases each year over the policy's term if you choose to take a monthly payout. In return, you pay a monthly or an annual premium. This kind of policy can be used to repay the balance of a mortgage</p> */}

                          <p>Term life insurance ends after a certain period of time and you get to choose how long the term will last. It can typically last from 10 to 30 years. You can consider various factors to determine whether term life is a good fit for you – for example, income, mortgage payments, and monthly expenses.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 2 )} as={Card.Header} eventKey={2}>
                        <div className="card-head" id="headingTwo">
                          <h2 className={showArrow !== 2 ? 'collapsed mb-0' : 'mb-0'}>
                            {/* 2. How can a decreasing term life policy help me financially? */}
                            2. What happens if I outlive my life insurance?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={2}>
                        <Card.Body>
                          {/* <p>Decreasing term life insurance is more affordable, it can work as a monthly income source, and you can get high coverage as well</p> */}

                          <p>If your term policy expires and you still have loved ones depending on you, it's best to renew your policy. At this point you could add more coverage or alter your policy to fit your needs.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 3 )} as={Card.Header} eventKey={3}>
                        <div className="card-head" id="headingThree">
                          <h2 className={showArrow !== 3 ? 'collapsed mb-0' : 'mb-0'}>
                            {/* 3. What are the advantages of buying an IncomeSense policy? */}
                            3. What's the best age to get life insurance?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={3}>
                        <Card.Body>
                          {/* <p>Your family can continue paying your mortgage and have an income even after your passing, you receive monthly payouts instead of a lump sum amount of money at the end of your insurance term, easy online application, no medical exam, fixed  premiums, and it's customizable to your financial needs</p> */}

                          <p>The right age would depend on your financial position and commitments. However, the best age would be as young as you possibly can. Buying life insurance at a young age means you may not need a medical exam, you can lock in your health rating, and it's one financial goal you can check off your list quite early.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 4 )} as={Card.Header} eventKey={4}>
                        <div className="card-head" id="headingFour">
                          <h2 className={showArrow !== 4 ? 'collapsed mb-0' : 'mb-0'}>
                            {/* 4. Why should I choose W&S for my insurance needs? */}
                            4. Under what circumstances can I ignore life insurance?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={4}>
                        <Card.Body>
                          {/* <p>Established in 1888, W&S is a stable and reliable financial institution with affordable life insurance options. As a highly rated financial group, W&S is the one you can count on for your financial needs</p> */}

                          <p>You should not ignore buying life insurance. Even if you think you're too young or have a lot saved, life insurance can help you protect your family's financial future because anything can happen. In fact, the earlier you get life insurance the more likely you are to generally lock in a lower premium and a good health rating for decades.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 5 )} as={Card.Header} eventKey={5}>
                        <div className="card-head" id="headingFive">
                          <h2 className={showArrow !== 5 ? 'collapsed mb-0' : 'mb-0'}>
                            {/* 5. Am I eligible to buy a decreasing term life insurance policy? */}
                            5. Can I cancel my life insurance policy?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={5}>
                        <Card.Body>
                          {/* <p>If you are a U.S citizen and a permanent legal resident in the country between the ages of 20 to 55, you are eligible to apply</p> */}

                          <p>Sure, you can. Much like most other contracts, a life insurance policy also expires. You can then chose to reapply or not keeping in mind your financial goals and commitments. Fees may apply for the early cancellation of certain policies.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 6 )} as={Card.Header} eventKey={6}>
                        <div className="card-head" id="headingSix">
                          <h2 className={showArrow !== 6 ? 'collapsed mb-0' : 'mb-0'}>
                            {/* 6. How can I decide the duration of the term? */}
                            6. Why do I need life insurance when I'm healthy?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={6}>
                        <Card.Body>
                          {/* <p>There are a few factors to consider while deciding on the term of your policy. Your age (the policy can cover you till you are 65 years of age), your children’s age (how many years you want them to have financial support), and if you have a mortgage (how many years remain on your mortgage)</p> */}

                          <p>Life insurance is to help protect your loved ones in the event of unforeseen circumstances. Life insurance can help assure you that your loved ones are taken care of.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>

                  {/* <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 7 )} as={Card.Header} eventKey={7}>
                        <div className="card-head" id="headingSeven">
                          <h2 className={showArrow !== 7 ? 'collapsed mb-0' : 'mb-0'}>
                            7. How much coverage can I get?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={7}>
                        <Card.Body>
                          <p>The coverage amount per month ranges from $500 to $10,000 (depending on how you qualify). Your monthly income, age, and other factors can determine your monthly insurance payout. Payouts can be capped by your age as well. For example, ages 20-30 it's capped at 150%, 31-40 at 125%, and 41-50 at 100%</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 pl-r">
                    <Card>
                      <Accordion.Toggle onClick={( e ) => clicked( e, 8 )} as={Card.Header} eventKey={7}>
                        <div className="card-head" id="headingSeven">
                          <h2 className={showArrow !== 8 ? 'collapsed mb-0' : 'mb-0'}>
                            8. How much does a decreasing insurance policy cost?
                          </h2>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={7}>
                        <Card.Body>
                          <p>Pricing depends on coverage amount, duration of the policy and underwriting results. You can easily get a quote with Bubble in a minute and decide how much coverage you want</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </div> */}
                </div>
              </div>
            </Accordion>

          </div>
        </div>
      </div>


    </Layout>
  );
}

export default WNS;
